import { useEffect, useState } from "react";
import { ListCard, MapViewFlyOut } from "../Shared";
import moment from "moment";
import { useGetTripsTrackQuery } from "../services";
import Scrollbars from "react-custom-scrollbars";
import TripsMap from "../Trips/TripsMap";
import EventsMap from "../Events/EventMap";
import { QIDragAndDropDetails } from "../../../components";
import { Dnd, MinimisedTab, useDndContainer, WindowTab } from "../Dnd";
import {
  extractKeyValuePairs,
  capitalizeAllFirstLetters,
  displayKeyName,
  valueFormatter,
} from "../utils";
import { useVariableDataQuery } from "../../../hooks/QIVariableDataQuery";
import { FMDPStorage } from "../../../shared/helper";
import { getLocalizedString } from "../../../shared/translation";
import { useGetPluginsQuery } from "../../ManagementPortal/services";
import { PluginContainer } from "../../Plugins";

export const ActivitiesAlternateContainer = ({}) => {
  const selectedPlateNumbers = FMDPStorage.get("selected-plate-numbers")?.length
    ? FMDPStorage.get("selected-plate-numbers")
    : [""];
  const [dockAlign, setDockAlign] = useState("false");
  const [maximizeMinimize, setmaximizeMinimize] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(24, "hours"),
    endDate: moment(),
  });
  const [trackMapRef, setTrackMapRef] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [resetCount, setResetCount] = useState(0);
  const [clientId, setClientId] = useState(null);
  const [highlight, setHighlight] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [tripTime, setTripTime] = useState(null);
  const [showEventsDetails, setShowEventsDetails] = useState(false);
  const [mode, setMode] = useState("PARKED");
  const [mapExpand, setMapExpand] = useState(false);
  const [mapPoint, setMapPoint] = useState(null);
  const [activitySearch, setActivitySearch] = useState("");
  const [showTrack, setShowTrack] = useState(false);
  const [currentTab, setCurrentTab] = useState("Map");

  //Pagination
  const pagination = { page, perPage, onPageChange: setPage, onPerPageChange: setPerPage };

  const { variableData } = useVariableDataQuery();

  const { activeBoxes, addNewBox, removeBox, revertBox, isItemsDragging, handleDragChange } =
    useDndContainer();

  //Get Client ID
  useEffect(() => {
    const selectedClientId = JSON.parse(localStorage.getItem("selected-app-id"));
    const currentUser = JSON.parse(localStorage.getItem("current-user"))?.client_apps?.find(
      (item) => {
        return item.id === selectedClientId;
      }
    );

    setClientId(currentUser?.client?.id);

    //clean up client id on component unmount
    return () => setClientId(null);
  }, []);

  //clean up calendar value on compoonent unmount
  useEffect(() => {
    return setResetCount((prev) => prev + 1);
  }, []);

  //on page change remvove row selection

  useEffect(() => {
    removeSelection();
  }, [page]);

  const currentUser = FMDPStorage.get("current-user");

  const selectedAppId = FMDPStorage.get("selected-app-id");
  const getFilterList = (type) => {
    const currentAppRoles = currentUser?.client_app_roles;
    let hasAdminRole = false;
    currentAppRoles?.filter((clientData) => {
      if (clientData?.client_app_id === selectedAppId) {
        clientData?.roles?.filter((role) => {
          if (role?.name === "Admin" || role?.name === "Fleet Admin") {
            hasAdminRole = true;
          }
        });
      }
    });
    if (type === "checkAdmin") {
      if (hasAdminRole === false) {
        return false;
      } else {
        return true;
      }
    } else {
      if (hasAdminRole === false && type === "device") {
        return "";
      } else if (hasAdminRole === false && type === "plateNo") {
        return selectedPlateNumbers?.length > 0 ? selectedPlateNumbers : "";
      } else {
        return "";
      }
    }
  };

  const { data, error, isLoading, isSuccess } = useGetTripsTrackQuery(
    clientId && {
      isAdmin: getFilterList("checkAdmin"),
      plate_number: selectedPlateNumbers,
      startTime: moment(selectedRange.startDate).valueOf(),
      endTime: moment(selectedRange.endDate).valueOf(),
      clientId: clientId,
      page: page - 1,
      per_page: perPage,
      search: true,
      q: activitySearch?.toUpperCase(),
    }
  );

  const removeSelection = () => {
    setSelectedRowId(null);
    setDeviceId(null);
    setTripTime(null);
    setShowEventsDetails(false);
    setDockAlign("cross");
  };

  const onRowClick = (data, id) => {
    if (selectedRowId === id) {
      removeSelection();
    } else {
      setSelectedRowId(id);
      setDeviceId(data?.data?.source_id);
      setTripTime({
        start_time: data?.data?.start_time,
        end_time: data?.data?.end_time || data?.data?.last_time,
      });
      setShowEventsDetails(data?.data);
      setMode(data?.mode);
      setDockAlign("split-down");
    }
  };

  const openDeviceModal = () => {
    setShowDetails(false);
    addNewBox(deviceId);
  };

  const getListDetails = () => {
    const keyValues = extractKeyValuePairs(showEventsDetails);
    const renderDetails = keyValues.map((pair) => {
      const key = Object.keys(pair)[0];
      let value = pair[key];

      return (
        <>
          {value !== null && !key.startsWith("_") && (
            <li className="details-block_other-data_item">
              <strong title={key} className="details-block_other-data_item_label">
                {getLocalizedString(key, displayKeyName(variableData, key))}
              </strong>
              <span title={value} className="details-block_other-data_item_value">
                {valueFormatter(key, value, variableData)}
              </span>
            </li>
          )}
        </>
      );
    });
    return (
      <section className="details-block_data-container">
        <ul className="details-block_data details-block_other-data">{renderDetails}</ul>
      </section>
    );
  };

  const funSetActive = () => {
    setCurrentTab("Map");
  };

  const showdetailsTabs = (tab) => {
    setCurrentTab(tab);
  };

  const { data: dataPlugins = { data: [], total_count: 0 } } = useGetPluginsQuery({
    page,
    per_page: perPage,
    order_by: "plugins.created_at",
    order_dir: "desc",
  });

  const addPlugins = dataPlugins.data.filter(
    (plugin) =>
      plugin.tracker_containers.map((s) => parseInt(s, 10)).includes(3) && plugin.is_enabled
  );
  const isPluginTab = addPlugins.some((plugin) => plugin.name === currentTab);
  const selectedPlugin = addPlugins.find((plugin) => plugin.name === currentTab);

  return (
    <>
      <div className="tracking-trips activities-tab">
        <main className="tracking-app-main-container item-list-right-layout">
          <article className={`tracking-app-main-container_block ${currentTab}`}>
            <MapViewFlyOut
              data={data?.activities || []}
              resetCount={resetCount}
              setResetCount={setResetCount}
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              error={error}
              listType={"Trips"}
              pagination={{ ...pagination, count: data?.total_count }}
              selectedPage={"Activities"}
              isLoading={isLoading}
              title={"Activities"}
              search_by_message="Search by Plate No.,IMEI and Type"
              setMapExpand={setMapExpand}
              simpleSearch={activitySearch}
              setSimpleSearch={setActivitySearch}
            >
              <Scrollbars>
                <ListCard
                  data={data?.activities || []}
                  cardType="Activities"
                  onRowClick={onRowClick}
                  highlight={highlight}
                  setHighlight={setHighlight}
                  selectedRowId={selectedRowId}
                />
              </Scrollbars>
            </MapViewFlyOut>
            <div className="map-and-tabs-container">
              <header className="tracking-app-main-container_block_header">
                <nav className="map-view-tabs">
                  <ul className="map-and-details_tabs">
                    <li>
                      <span
                        className={`map-and-details_tabs_item ${currentTab === "Map" && "active"}`}
                        onClick={funSetActive}
                      >
                        {getLocalizedString("map", "Map")}
                      </span>
                    </li>
                    {addPlugins &&
                      addPlugins.map((plugin, index) => (
                        <li key={index}>
                          <span
                            className={`map-and-details_tabs_item ${
                              currentTab === plugin.name && "active"
                            }`}
                            onClick={() => showdetailsTabs(plugin.name)}
                          >
                            {getLocalizedString("plugins", plugin.name)}
                          </span>
                        </li>
                      ))}
                  </ul>
                </nav>
              </header>
              <article
                className={`map-and-details ${dockAlign} ${
                  maximizeMinimize ? "minimized-details" : ""
                }`}
              >
                {currentTab === "Map" ? (
                  <TripsMap
                    dockAlign={dockAlign}
                    mapExpand={mapExpand}
                    deviceId={deviceId}
                    selectedRange={tripTime}
                    maximizeMinimize={maximizeMinimize}
                  />
                ) : isPluginTab ? (
                  <PluginContainer selectedPlugin={selectedPlugin} />
                ) : null}

                {showEventsDetails && (
                  <article className={`details-block`}>
                    <QIDragAndDropDetails
                      setDetails={setShowEventsDetails}
                      setPopup={openDeviceModal}
                      deviceData={showEventsDetails || {}}
                      removeRowSelection={removeSelection}
                      setDockAlign={setDockAlign}
                      dockAlign={dockAlign}
                      deviceId={deviceId}
                      setDeviceId={setDeviceId}
                      showTrack={showTrack}
                      setShowTrack={setShowTrack}
                      headerName={"Plate Number"}
                      listDetails={getListDetails()}
                      selectedVehiclePlate={showEventsDetails?.plate_number}
                      maximizeMinimize={maximizeMinimize}
                      setmaximizeMinimize={setmaximizeMinimize}
                      realTimedata={{}}
                    />
                  </article>
                )}
              </article>
            </div>
          </article>
        </main>
        <Dnd
          layoutName="trackingLayout"
          isItemsDragging={isItemsDragging}
          handleDragChange={handleDragChange}
          setDetails={setShowDetails}
        >
          {Object.keys(activeBoxes).map((key) => (
            <WindowTab
              key={key}
              id={key}
              label={key}
              {...activeBoxes[key].props}
              onClose={removeBox}
              onMinimise={revertBox}
              setShowDetails={setShowDetails}
              setShowTrack={setShowTrack}
              setDockAlign={setDockAlign}
              setDeviceId={setDeviceId}
              selectedVehiclePlate={showEventsDetails?.plate_number}
              setHighlight={setHighlight}
              removeLayerOnClose={false}
              removeRowSelection={removeSelection}
            >
              {getListDetails()}
            </WindowTab>
          ))}
        </Dnd>
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Components from "../../../components";
import { FormContainer } from "../Shared/FormContainer";
import {
  useCreateLocalizationUploadMutation,
  useGetApplicationVariantsQuery,
  useGetLanguagesQuery,
} from "../services";
import { LocalizationUploadFormSchema } from "../validations";
import { getCsvDetails } from "../../../shared/helper";
import { getLocalizedString } from "../../../shared/translation";
import { useForm, useDropDownSearch } from "../../../hooks";

export const ImportLocalizationForm = ({ idToEdit = null, closeForm, setImportSuccess }) => {
  const dropdownParams = { per_page: 1000, order_by: "name" };
  const [initialValues, setInitialValues] = useState({
    csv_file: null,
    update_if_exist: false,
    locale_id: "",
    application_variant_id: "",
    csv_column_mappings: {
      text_id_column: "",
      value_column: "",
    },
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: LocalizationUploadFormSchema,
    onSubmit: (value) => {
      handleFormSubmit(value);
    },
  });

  const [headers, setHeaders] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [isCsvUpload, setIsCsvUpload] = useState(false);

  useEffect(() => {
    let tempArray = [];
    Object.keys(formik.values.csv_column_mappings).map((key) => {
      if (formik.values.csv_column_mappings[key]) {
        tempArray.push(formik.values.csv_column_mappings[key]);
      }
    });
    setSelectedHeaders(tempArray);
  }, [formik.values.csv_column_mappings]);

  useEffect(() => {
    if (formik.values.csv_file) {
      getCsvDetails(formik.values.csv_file, updateCsvDetails);
    }
  }, [formik.values.csv_file]);

  const { create: createLocalization, data: responseData } = useForm({
    createMutation: useCreateLocalizationUploadMutation,
    updateMutation: useCreateLocalizationUploadMutation,
    closeForm: closeForm,
    setError: formik.setErrors,
  });

  useEffect(() => {
    if (responseData) {
      setImportSuccess(responseData);
    }
  }, [responseData]);

  // Get the langauges list using Dropdown Search
  const {
    data: languages,
    setSearchKey: setLanguagesSearch,
    isSuccess: isLanguagesSuccess,
  } = useDropDownSearch({
    useData: useGetLanguagesQuery,
    simpleSearchKey: "name_cont",
    selectedIds: formik.values.locale_id,
    additionalParams: { ...dropdownParams },
  });

  // Get the application variants list using Dropdown Search
  const {
    data: applicationVariants,
    setSearchKey: setApplicationVariantsSearch,
    isSuccess: isApplicationVariantsSuccess,
  } = useDropDownSearch({
    useData: useGetApplicationVariantsQuery,
    simpleSearchKey: "name",
    selectedIds: formik.values.application_variant_id,
  });

  const handleFormSubmit = (formData) => {
    if (idToEdit === null) {
      idToEdit = formik.values.group_id;
    }
    const formObj = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === "csv_column_mappings") {
        formObj.append(key, JSON.stringify(formData[key]));
      } else {
        if (formData[key] !== null) {
          formObj.append(key, formData[key]);
        }
      }
    });
    createLocalization({ formData: formObj });
  };

  const updateCsvDetails = (headers, csvSize) => {
    setHeaders(headers);
    setIsCsvUpload(true);
  };

  const showWarningMessage = (value) => {
    let countValueOccurence = 0;
    selectedHeaders.map((header) => {
      if (header === value) {
        countValueOccurence++;
      }
    });
    if (countValueOccurence > 1) {
      return true;
    } else {
      return false;
    }
  };

  const handleDisableField = () => {
    if (
      formik.values.csv_file &&
      (!formik.errors.csv_file ||
        formik.errors.csv_file === "Import has failed. Please check the file.")
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <FormContainer
      closeForm={closeForm}
      handleFormSubmit={formik.handleSubmit}
      customResourceName="Import Localization"
    >
      <Components.QICustomSelect
        label="Language"
        value={formik.values.locale_id}
        onChange={(value) => {
          formik.setFieldValue("locale_id", value);
          setLanguagesSearch("");
        }}
        onSearch={setLanguagesSearch}
        error={formik.touched.locale_id && formik.errors.locale_id}
      >
        <li value="">Select</li>
        {languages?.data?.map((language) => (
          <li key={language?.id} value={language?.id} title={language?.name}>
            {language?.name}
          </li>
        ))}
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Profile"
        value={formik.values.application_variant_id}
        onChange={(value) => {
          formik.setFieldValue("application_variant_id", value);
        }}
        error={formik.touched.application_variant_id && formik.errors.application_variant_id}
      >
        <li value="">Select</li>
        {isApplicationVariantsSuccess &&
          applicationVariants?.data?.map((app) => (
            <li key={app?.id} value={app?.id} title={app?.name}>
              {app?.name}
            </li>
          ))}
      </Components.QICustomSelect>

      <Components.QIFileUploader
        label="Labels And Messages"
        placeholder={getLocalizedString("only_csv_file", "Only .csv files")}
        value={formik.values.csv_file}
        onChange={(files) => formik.setFieldValue("csv_file", files[0])}
        error={formik.touched.csv_file && formik.errors.csv_file}
      />

      <Components.QISwitch
        label={getLocalizedString("update_if_exists", "Update if exists")}
        {...formik.getFieldProps("update_if_exist")}
        error={formik.touched.update_if_exist && formik.errors.update_if_exist}
        onChange={() => formik.setFieldValue("update_if_exist", !formik.values.update_if_exist)}
        disabled={handleDisableField()}
        readOnly={handleDisableField()}
      />

      <Components.QICustomSelect
        label="Key"
        {...formik.getFieldProps("csv_column_mappings.text_id_column")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.text_id_column", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.text_id_column &&
          formik?.errors?.csv_column_mappings?.text_id_column
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.text_id_column)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={handleDisableField()}
        labelClassName="key"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>

      <Components.QICustomSelect
        label="Value"
        {...formik.getFieldProps("csv_column_mappings.value_column")}
        onChange={(value) => {
          formik.setFieldValue("csv_column_mappings.value_column", value);
        }}
        error={
          formik?.touched?.csv_column_mappings?.value_column &&
          formik?.errors?.csv_column_mappings?.value_column
        }
        warningMessage={`${
          showWarningMessage(formik.values.csv_column_mappings.value_column)
            ? "Selected multiple times."
            : ""
        }`}
        disabled={handleDisableField()}
        labelClassName="value"
      >
        <>
          <li value="">{getLocalizedString("select", "Select")}</li>
          {headers?.map((header, index) => (
            <li
              value={header}
              key={index}
              className={`${selectedHeaders.includes(header) ? "active-fields" : ""}`}
            >
              {header}
            </li>
          ))}
        </>
      </Components.QICustomSelect>
    </FormContainer>
  );
};

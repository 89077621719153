import React, { useState, useEffect } from "react";
import { AdminSharedHeader, AdminSharedTable, AdminSharedDeleteModal } from "../Shared";
import { useGetVariablesQuery, useDeleteVariableMutation } from "../services";
import { useSearchQuery, usePagination, useQuery } from "../../../hooks";
import { VariableTypeForm } from "./Form";
import { Link } from "react-router-dom";
import { classifications } from "../../../shared/dropdownLists";

export const VariablesContainer = () => {
  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, setAdvanceSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_or_display_name_or_section_name_or_unit_name_cont",
    additionalSimpleSearchKeys: ["display_name_cont"],
  });

  const queryParams = {};
  const query = useQuery();
  let filterHeader = "";
  const filters = [{ label: " Device Model: ", key: "device_model_id" }];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  const {
    data = { data: [], total_count: 0 },
    isFetching,
    isSuccess,
    error,
  } = useGetVariablesQuery({
    page,
    per_page: perPage,
    order_by: "variables.created_at",
    order_dir: "desc",
    ...queryParams,
    q,
  });

  const [deleteVariableType, { isSuccess: deleteSuccess, reset: resetDeleteVariableType }] =
    useDeleteVariableMutation();

  const [idToEdit, setIdToEdit] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const createNewHandler = () => {
    setShowForm(true);
    setIdToEdit(null);
  };

  const editClickHandler = (datum) => {
    setShowForm(true);
    setIdToEdit(datum.id);
  };

  const deleteClickHandler = (datum) => {
    setShowDeleteWarning(true);
    setIdToDelete(datum.id);
  };

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data?.findIndex((datum) => datum.id === id);

    if (index > -1) {
      name = data?.data[index].display_name;
    }
    return name;
  };

  const headers = [
    {
      label: "Classification",
      nestedValue: true,
      className: "classification",
      getNestedValue: ({ classification_type }) => {
        const classification = classifications.find(
          (classif) => classif.id === classification_type
        );
        return classification ? classification.name : null;
      },
    },
    { label: "Display Name", key: "display_name", className: "display-name" },
    { label: "Section", key: "section_name", className: "section-name" },
    { label: "Name", key: "name", className: "name" },
    { label: "Type", key: "variable_type", className: "variable-type" },
    { label: "Display Precision", key: "precision", className: "precision" },
    { label: "Unit", key: "unit_name", className: "unit-name" },
    {
      label: "Device Models",
      className: "device-models text-center",
      nestedValue: true,
      alternate: "See associated device models",
      getNestedValue: ({ id, device_model_count, display_name }) => {
        return (
          <>
            {device_model_count > 0 ? (
              <Link
                to={`/superadmin/devices/models?variable_id=${id}&name=${display_name}`}
                style={{ textDecoration: "none" }}
              >
                {device_model_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    { label: "Created on", key: "created_at", className: "date" },
  ];

  useEffect(() => {
    if (deleteSuccess) {
      setShowDeleteWarning(false);
      resetDeleteVariableType();
    }
    return () => resetDeleteVariableType();
  }, [deleteSuccess]);

  return (
    <>
      <article className="main-container">
        <AdminSharedHeader
          createNewHandler={createNewHandler}
          groupName="other"
          heading="Configurations"
          handleSearchKey={(value) => setSimpleSearch(value)}
          simpleSearchPlaceHolder="Search Display Name, Section, Name, Unit"
          filterText={filterHeader && `Filtered by ${filterHeader}`}
        />

        <AdminSharedTable
          isLoading={isFetching}
          error={error}
          isSuccess={isSuccess}
          data={data.data}
          headers={headers}
          pagination={{ ...pagination, count: data.total_count }}
          onEdit={editClickHandler}
          className="variable-types"
          onDelete={deleteClickHandler}
          auditResource="Variable"
          auditKey="name"
        />
      </article>

      {showForm && <VariableTypeForm idToEdit={idToEdit} closeForm={() => setShowForm(false)} />}

      <AdminSharedDeleteModal
        show={showDeleteWarning}
        resourceName="variable"
        getDeleteItemName={() => getDeleteItemName(idToDelete)}
        onHide={() => setShowDeleteWarning(false)}
        onDelete={() => deleteVariableType({ id: idToDelete })}
      />
    </>
  );
};
